import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import ClipLoader from 'react-spinners/ClipLoader';

type Props = {
  file?: Blob;
  loading: boolean;
  isFullPreview: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  pageWidth: number;
  onImageGenerated?: (imageUrl: string) => void;
};

const FilePreview: FC<Props> = ({
  file,
  loading,
  isFullPreview,
  pageWidth = 500,
  onClick,
  onImageGenerated,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isLoadingFile = !file || loading;
  const [width, setWidth] = useState(pageWidth);
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null);
  const [magnifierState, setMagnifierState] = useState({
    top: 0,
    left: 0,
    offsetX: 0,
    offsetY: 0,
  });
  const [isVisible, setIsVisible] = useState(false);
  const magnifierRadius = 150;
  const zoomFactor = 3; // Zoom factor for the magnifier
  const scale = 0.7;

  useEffect(() => {
    /**
     * Handles the resize event and adjusts the width of the component accordingly.
     */
    const handleResize = () => {
      let drawWidth = window.innerWidth;
      let drawHeight = window.innerHeight;
      let width = drawWidth;
      let height = width / scale;

      if (width > drawWidth || height > drawHeight) {
        height = drawHeight;
        width = height * scale;
      }

      setWidth(width);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRenderSuccess = () => {
    try {
      const canvasElements = document.getElementsByTagName('canvas');
      const canvas = canvasElements[canvasElements.length - 1];

      if (!canvas) {
        throw new Error('No canvas element found in the document');
      }

      setCanvasRef(canvas); // Save the canvas reference for magnification
      onImageGenerated?.(canvas.toDataURL('image/png'));
    } catch (error) {
      console.error('Error capturing canvas:', error);
    }
  };

  //handle magnification:
  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!canvasRef || !ref.current) return;

    setIsVisible(true);

    // Get the parent container's position and size
    const parentRect = ref.current.getBoundingClientRect();

    // Get the canvas's position and size
    const canvasRect = canvasRef.getBoundingClientRect();

    // Calculate the scaling factors for the actual canvas resolution vs. rendered size
    const scaleX = canvasRef.width / canvasRect.width;
    const scaleY = canvasRef.height / canvasRect.height;

    // Mouse position relative to the parent container
    const mouseX = e.clientX - parentRect.left;
    const mouseY = e.clientY - parentRect.top;

    // Mouse position relative to the rendered canvas
    const relativeMouseX = e.clientX - canvasRect.left;
    const relativeMouseY = e.clientY - canvasRect.top;

    // Map the mouse coordinates to the actual canvas content
    const offsetX = relativeMouseX * scaleX * zoomFactor - magnifierRadius / 2;
    const offsetY = relativeMouseY * scaleY * zoomFactor - magnifierRadius / 2;

    // Ensure the magnifier is positioned correctly relative to the canvas in the UI
    const magnifierTop = mouseY - magnifierRadius;
    const magnifierLeft = mouseX - magnifierRadius;

    setMagnifierState({
      top: magnifierTop, // Adjust magnifier position
      left: magnifierLeft,
      offsetX: offsetX, // Adjust magnified content alignment
      offsetY: offsetY,
    });
  };

  const handleMouseLeave = () => setIsVisible(false);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
      }}
      ref={ref}
      onClick={onClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {isLoadingFile ? (
        <ClipLoader color="#35b3ee" />
      ) : isFullPreview ? (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} width={width} scale={scale} onRenderSuccess={handleRenderSuccess} />
        </Document>
      ) : (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} width={pageWidth} />
        </Document>
      )}
      {canvasRef && isVisible && (
        <div
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            border: '4px solid #efefef',
            boxShadow: '0 5px 10px -2px rgba(0, 0, 0, 0.3)',
            zIndex: 99,
            width: magnifierRadius,
            height: magnifierRadius,
            borderRadius: '50%',
            background: `url("${canvasRef.toDataURL()}") no-repeat #fff`,
            backgroundSize: `${canvasRef.width * zoomFactor}px ${canvasRef.height * zoomFactor}px`,
            backgroundPositionX: -magnifierState.offsetX,
            backgroundPositionY: -magnifierState.offsetY,
            top: `${magnifierState.top + magnifierRadius / 2}px `,
            left: `${magnifierState.left + magnifierRadius / 2}px`,
            transition: 'opacity 0.2s',
            opacity: isVisible ? 1 : 0,
          }}
        />
      )}
    </div>
  );
};

export default FilePreview;
