import { SetStateAction, useCallback, useEffect, useState } from 'react';

import { Area } from 'components/EasyCrop/types';

const useCrop = () => {
  const [crop, setCrop] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<Area | undefined>(undefined);

  const onCropChange = useCallback((crop: SetStateAction<{ x: number; y: number }>) => {
    setCrop(crop);
  }, []);

  const onZoomChange = useCallback((zoom: SetStateAction<number>) => {
    setZoom(zoom);
  }, []);

  const onCropComplete = useCallback((croppedAreaPercentage: Area) => {
    setCroppedArea(croppedAreaPercentage);
  }, []);

  const resetZoom = useCallback((factor: number = 1) => {
    setZoom(factor);
  }, []);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.deltaY < 0) {
        // zoom in
        setZoom((prev) => (prev < 5 ? prev + 0.01 : prev));
      } else if (e.deltaY > 0) {
        setZoom((prev) => (prev > 1 ? prev - 0.01 : prev));
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [setZoom]);

  return {
    crop,
    zoom,
    onCropChange,
    onZoomChange,
    onCropComplete,
    setCroppedArea,
    croppedArea,
    resetZoom,
  };
};

export default useCrop;
