import { useMutation, useQueryClient } from 'react-query';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as R from 'ramda';
import { Asset, Side, SideWithAsset } from 'shared-types';

import SortablePageItem from 'components/Book/SortablePageItem';

import API from 'services/API';

interface ProductSidesPreviewsListProps {
  sides: SideWithAsset[];
  pdfPreviews: Map<string, string>;
}

const ProductSidesPreviewsList = ({ sides, pdfPreviews }: ProductSidesPreviewsListProps) => {
  const queryClient = useQueryClient();
  const sidesCount = sides.length; // Get the number of sides

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { mutate: swapPageNumbersMutation } = useMutation(
    'updatePageNumber',
    (params: { sessionId: Asset['sessionId']; sideId: Side['id']; targetSideId: Side['id'] }) =>
      API.swapPageNumbers(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
      },
    },
  );

  if (R.isEmpty(sides)) return null;

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = sides.findIndex((side) => side.id === active.id);
      const newIndex = sides.findIndex((side) => side.id === over.id);
      const sideId = sides[newIndex].id;
      const targetSideId = sides[oldIndex].id;
      const sessionId = sides[0].asset.sessionId;
      swapPageNumbersMutation({ sessionId, sideId, targetSideId });
    }
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={sides.map((side) => side.id)} strategy={verticalListSortingStrategy}>
        {sides.map((side, index) => (
          <SortablePageItem
            side={side}
            key={side.id}
            index={index}
            sidesCount={sidesCount}
            previewFile={
              side.previewImageUrl && side.previewImageUrl !== ''
                ? side.previewImageUrl
                : pdfPreviews.get(side.id) || side.previewImageUrl
            }
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default ProductSidesPreviewsList;
