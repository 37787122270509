import { useTranslation } from 'react-i18next';
import { MdOutlineWindow, MdZoomInMap, MdZoomOutMap } from 'react-icons/md';
import { RiCrop2Fill } from 'react-icons/ri';
import { useMutation } from 'react-query';
import {
  DeleteOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Actions, Product, RotationDirection, Side } from 'shared-types';

import Box from 'components/Box/Box';

import useProductsStore from 'hooks/stores/useProductsStore';

import API from 'services/API';

type Props = {
  onRotate: (direction: RotationDirection) => void;
  onFlip: () => void;
  onPreview?: () => void;
  onCrop?: () => void;
  onZoomOut?: () => void;
  onSetOriginal?: () => void;
  onSetToFit?: () => void;
  onUndo?: () => void;
  productId: Product['id'];
  sideId: Side['id'];
  showActions?: Actions[];
  disableActions?: Actions[];
};

const actionStyle = {
  cursor: 'pointer',
  alignSelf: 'end',
  marginLeft: 10,
};

const Toolbox = ({
  onRotate,
  onFlip,
  onPreview,
  onCrop,
  onZoomOut,
  onSetOriginal,
  onSetToFit,
  onUndo,
  productId,
  sideId,
  showActions = [
    'rotate',
    'flip',
    'preview',
    'delete',
    'zoom',
    'crop',
    'zoomout',
    'reset',
    'undo',
    'set-to-fit',
  ],
  disableActions,
}: Props) => {
  const { t } = useTranslation();
  const removeAssetFromSide = useProductsStore((state) => state.removeAssetFromSide);
  const useRemoveAssetFromSideMutation = useMutation(
    'removeAssetFromSide',
    (variables: { productId: string; sideId: Side['id'] }) =>
      API.removeAssetFromSide(variables.productId, variables.sideId),
    {
      onError: () => {
        // queryClient.invalidateQueries('products');
      },
      onSuccess: () => {
        // queryClient.invalidateQueries('products');
      },
    },
  );

  const handleRemoveAssetFromSide = () => {
    removeAssetFromSide(productId, sideId);
    useRemoveAssetFromSideMutation.mutate({ productId, sideId });
  };

  return (
    <Box display="flex" justifyContent="space-between" marginY="1rem">
      {showActions?.includes('delete') && (
        <Tooltip placement="top" title={t('SideActions.DeleteImage')}>
          <Button
            type="default"
            danger
            onClick={handleRemoveAssetFromSide}
            disabled={disableActions?.includes('delete')}
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      )}

      <Box display="flex" justifyContent={'space-between'} alignItems="center">
        {showActions?.includes('undo') && (
          <Tooltip placement="top" title={t('SideActions.Undo')}>
            <Button
              type="default"
              style={actionStyle}
              onClick={onUndo}
              disabled={disableActions?.includes('undo')}
              icon={<UndoOutlined />}
            />
          </Tooltip>
        )}
        {showActions?.includes('rotate') && (
          <>
            <Tooltip placement="top" title={t('SideActions.RotateRight')}>
              <Button
                type="default"
                style={actionStyle}
                onClick={() => onRotate('left')}
                disabled={disableActions?.includes('rotate')}
                icon={<RotateRightOutlined />}
              />
            </Tooltip>
            <Tooltip placement="top" title={t('SideActions.RotateLeft')}>
              <Button
                type="default"
                style={actionStyle}
                onClick={() => onRotate('right')}
                disabled={disableActions?.includes('rotate')}
                icon={<RotateLeftOutlined />}
              />
            </Tooltip>
          </>
        )}
        {showActions?.includes('flip') && (
          <Tooltip placement="top" title={t('SideActions.FlipHorizontal')}>
            <Button
              type="default"
              style={actionStyle}
              onClick={onFlip}
              disabled={disableActions?.includes('flip')}
              icon={<SwapOutlined />}
            />
          </Tooltip>
        )}

        {showActions?.includes('zoom') && (
          <Tooltip placement="top" title={t('SideActions.PreviewAndMoreActions')}>
            <Button
              type="default"
              style={actionStyle}
              onClick={onPreview}
              disabled={disableActions?.includes('zoom')}
              icon={<ZoomInOutlined />}
            />
          </Tooltip>
        )}

        {showActions?.includes('reset') && (
          <Tooltip placement="top" title={t('SideActions.ResetToCrop')}>
            <Button
              type="default"
              style={actionStyle}
              onClick={onSetOriginal}
              icon={<MdOutlineWindow />}
            />
          </Tooltip>
        )}
        {showActions?.includes('zoomout') && (
          <Tooltip placement="top" title={t('SideActions.ZoomOut')}>
            <Button
              type="default"
              style={actionStyle}
              onClick={onZoomOut}
              icon={<MdZoomOutMap />}
            />
          </Tooltip>
        )}
        {showActions?.includes('set-to-fit') && (
          <Tooltip placement="top" title={t('SideActions.SetToFit')}>
            <Button
              type="default"
              style={actionStyle}
              onClick={onSetToFit}
              icon={<MdZoomInMap />}
            />
          </Tooltip>
        )}
        {showActions?.includes('crop') && (
          <Tooltip placement="top" title={t('SideActions.Crop')}>
            <Button
              type="default"
              style={actionStyle}
              disabled={disableActions?.includes('crop')}
              onClick={onCrop}
              icon={<RiCrop2Fill />}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default Toolbox;
